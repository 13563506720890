<template>
  <div
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 25%;
    "
    v-loading="loading"
  >
    <h1>{{ message }}</h1>
    <h1 v-if="status">The window will close in {{ time }} seconds</h1>
  </div>
</template>
<script setup>
import { onMounted, ref, nextTick } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import request from "@/utils/request.js";
const route = useRoute();
const store = useStore();
onMounted(() => {
  console.log("route", route.query);
  getIn();
});
const message = ref("");
const status = ref(false);
const time = ref(5);
const loading = ref(false);
async function getIn() {
  let { PayerID, paymentId, token } = route.query;
  if (!PayerID) {
    message.value = "Payment failed!";
    return;
  }
  loading.value = true;
  const { code } = await request.get("/system/paypal/pay/success", {
    params: { PayerID, paymentId, token },
  });
  if (code == 200) {
    loading.value = false;
    status.value = true;
    message.value = "Payment successful!";
    bus.emit("paySuccess", "1");
    // nextTick(() => {
    //     requestUserInfo();
    // });
  } else {
    status.value = false;
    message.value = "Payment failed!";
  }
  if (status.value) {
    let timer = setInterval(() => {
      if (time.value == 0) {
        clearTimeout(timer);
        window.close();
        return;
      }
      time.value = time.value - 1;
    }, 1000);
  }
}
// 请求用户信息
async function requestUserInfo() {
  const { code, data } = await request.get("/system/FaUser/info");
  if (code == 200) {
    store.commit("setUserInfo", JSON.stringify(data), true);
  }
}
function showMsg() {
  bus.emit("showPaypalMsg", true);
}
</script>
